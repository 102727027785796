import React from "react";

import Footer from "../layout/footer/Footer";
import Navbar from "../layout/header/Navbar";
import Layout from "../layout/Layout";
import PageHeader from "../team/PageHeader";
import HeroSix2 from "../../themes/index-6/HeroSix2";
import image5 from "../../img/wearable.png"




export default function Article6() {

  
  return (
    <Layout>
            <Navbar darkBg/>
      <HeroSix2 />
    
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="">
              <div className="about-content-left section-heading">
              <h3 style={{color:'#e95d04'}}>
                New research promises improved design and interfacing of exosuits

                          </h3>
                          <br/>
                          <p>Low back pain (LBP) is a leading cause of disability for occupational tasks involving repetitive lifting
contributing to compressive forces on the lumbosacral joint. (EMG)-driven musculoskeletal (EMS) models
offer a novel personalized approach to assess the compressive forces during lifting. However, these
models typically rely on multiple wearable sensors, which reduces the modelss' practicality for real-world
applications. In a recent early access publication by <a  style={{color:'#f45652'}} href='https://www.linkedin.com/in/janwillemrook/?originalSubdomain=nl'>Jan Willem A. Rook</a>, 
<a  style={{color:'#f45652'}} href='https://www.linkedin.com/in/massimo-sartori-0612296/?originalSubdomain=nl'> Massimo Sartori </a> and <a  style={{color:'#f45652'}} href='https://irfanrefai.com/'> Irfan Refai </a>
from SWAG partner <a  style={{color:'#f45652'}} href='https://www.utwente.nl/en/'>University of Twente</a> an innovative approach was developed that reduces the number
of EMG sensors required enhancing the wearability of the EMS.</p><img
                  src={image5}
                  style={{borderRadius:'10px',width:'350px',float:'right',marginLeft:'20px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <p>Muscle groups are controlled by a limited set of activation patterns or “synergies,” which allows the central
nervous system to simplify coordination during complex movements. This principle was utilized in the EMS
model to capture necessary data from fewer EMG sensors. To validate this approach, the researchers
designed a study with twelve participants performing common lifting techniques, including squat, stoop,
unilateral twist, and bilateral twist, under varying loads of 0 kg, 7.5 kg, and 15 kg. The analysis revealed
that two muscle synergies were sufficient to account for 91% of the variance in muscle activation during
these tasks. </p>
                <p>Building on this synergy-based model, the researchers identified strategic EMG sensor placements for
each participant to estimate the activity of four additional, unmeasured muscle groups. They then
compared the estimated muscle activity with data from the complete EMG sensor array. The reduced two
sensor setup demonstrated high correlation achieving median R² values between 0.70 and 0.86, and root
mean squared errors (RMSE) of 0.02 to 0.04. This showed that synergy-based sensor reduction could
retain muscle activity information while substantially lowering the required sensor count.</p>
<p>By reducing the number of sensors without compromising accuracy, this model enhances wearability,
making biomechanical monitoring more accessible for workplace use. This innovation will be built on in the
SWAG project, which focuses on fabric-based soft robotics, integrated sensing, and real-time motion
assistance. SWAG aims to provide adaptive support for complex movements by integrating distributed
control and AI, with one of the use cases being occupational assistance. This synergy-based model can
directly benefit ergonomic assessments and interventions for workers prone to LBP. Additionally, the
model’s application in exoskeleton or exosuit design, where sensor count and placement are crucial to
usability, helps meet one of the goals of SWAG to overcome limitations in wearable devices by optimising
sensor placement and ensuring accurate real-time support.</p>
<p>Read the publication <a  style={{color:'#f45652'}} href='https://ieeexplore.ieee.org/document/10759783'>here</a>.</p>
 
 </div>      </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
